// Footer.js
import * as React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #e8e8e8; 
  padding: 40px 0;
  text-align: center;
`;

const Links = styled.div`
  margin-bottom: 30px;
`;

const LinkItem = styled.a`
  color: #333; 
  text-decoration: none;
  margin: 0 15px;
  font-size: 1rem;
  font-Family: "Lato", sans-serif;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  color: #333; 
  font-size: 0.8rem;
  font-Family: "Lato", sans-serif;
  font-weight: 300;
`;

export default function Footer() {
  return (
    <FooterContainer>
      <Links>
        {/* <LinkItem href="#">About</LinkItem>
        <LinkItem href="#">Community</LinkItem>
        <LinkItem href="#">Contact</LinkItem> */}
      </Links>
      <Copyright>
        Copyright © 2024 PhoFi.Art All rights reserved.
      </Copyright>
    </FooterContainer>
  );
}