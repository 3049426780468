import * as React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom'; 
import Header from './Header';
import Footer from './Footer';
import ComingSoon from './ComingSoon'; 
import SecondaryPage from './SecondaryPage'; 
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Lato", sans-serif',
  },
  palette: {
    background: {
      default: '#f1f1f1',
    },
  },
});

function App() {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: 0, margin: 0 }}>
        {location.pathname !== '/' && <Header />} 
        <Routes>
          <Route path="/" element={<ComingSoon />} /> 
          <Route path="/secondary" element={<SecondaryPage />} /> 
        </Routes>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;