// Content.js
import * as React from 'react';
import styled from 'styled-components';
import logo from './Images/logo.png';
import backgroundImage from './Images/logo-background.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Section = styled.section`
  text-align: center;
  padding:20px 0px;
  text-align: left; 
  background: #f1f1f1;
  @media (min-width: 768px) {
    padding: 120px 40px;
  }
`;

const SubTitle = styled.p`
  font-size: 1.3rem; 
  font-weight: 700;
  margin: 20px;
  margin-bottom: 15px;
  color: #777;
  font-family: "Lato", sans-serif;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 3.5rem;
  margin: 20px;
  margin-bottom: 0px;
  color: black;
  font-Family: "Lato", sans-serif;
  font-weight: 900;
  text-align: left;
  letter-spacing: -2px; 
  line-height: 0.9; 

  @media (min-width: 768px) {
    font-size: 3.5rem;
  }
`;


const Description = styled.p`
  font-size: 1rem;
  margin:20px;
  margin-top:20px ;
  margin-bottom: 30px;
  color: #777; 
  max-width: 400px; 
  font-Family: "Lato", sans-serif;
  font-weight: 400;
  text-align: left;
  line-height: 1.3; 
  letter-spacing: -0.3px;
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
  overflow: hidden;
  margin-bottom: 10px;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BuyButton = styled.button`
  color: black;
  border: 2px solid black;
  margin: 20px;
  padding: 15px 30px;
  font-size: 1.1rem; 
  font-weight: 900;
  font-Family: "Lato", sans-serif;
  border-radius: 30px; 
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 

  &:hover {
    background-color: #ccc; 
  }
`;

const ContentContainer = styled.div`
  background-color: #f1f1f1; /* Cor de fundo desejada */
`;

const MainTitle = styled.h1`
  font-size: 3.5rem; 
  font-weight: 900;
  margin: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 50px;
  color: black;
  font-family: "Lato", sans-serif;
  text-align: center;
  letter-spacing: -2px; 
  line-height: 0.9; 

  @media (min-width: 768px) {
    font-size: 5rem; 
  }
`;

const MainSubTitle = styled.p`
  font-size: 1.1rem; 
  font-weight: 400;
  margin: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #000000;
  font-family: "Lato", sans-serif;
  text-align: center;
`;

const LogoContainer = styled.div`
  background-image: url(${backgroundImage});
  background-size: auto 130%; // A imagem será exibida inteira, mantendo a proporção
  background-position: center; 
  background-repeat: no-repeat;
  display: flex;
  justify-content: center; 
  align-items: center;      
  height: 220px;
  width: auto;       
  margin: 0 auto; 
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr; // Uma coluna em telas menores

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr; // Duas colunas em telas maiores
    gap: 20px; // Espaçamento entre as colunas
  }
`;

function ContentSection({ subtitle, title, description, videoId }) {
  return (
    <Section>
      <SubTitle>{subtitle}</SubTitle>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <VideoContainer>
        <Iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;   
 picture-in-picture"
          allowFullScreen   

        ></Iframe>
      </VideoContainer>
      <BuyButton>Comprar</BuyButton>
    </Section>
  );
}

export default function Content() {
  return (
    <ContentContainer>
      <MainTitle>Your idols,<br />your phone<br />& your fingers</MainTitle>
      <MainSubTitle>Draw Any Idols, Step by Step</MainSubTitle>
      <LogoContainer> 
        <img src={logo} alt="Logotipo" style={{ maxHeight: '220px' }} /> 
      </LogoContainer>
      <KeyboardArrowDownIcon sx={{ display: 'block', margin: '20px auto', fontSize: 60, color: 'silver' }} />

      <GridContainer> {/* Envolve os ContentSection com o GridContainer */}
        <ContentSection
          subtitle="PhoFi technique"
          title="Chibi Style" 
          description="Don't use what you're about to learn to portray your idol in a negative light or to encourage rivalries on the internet. PhoFiArt is a super power that connects you to your idol." 
          videoId="5d83fOhb7_M" 
        />
        <ContentSection 
          subtitle="PhoFi technique"
          title="Chibi+AI" 
          description="Don't use what you're about to learn to portray your idol in a negative light or to encourage rivalries on the internet. PhoFiArt is a super power that connects you to your idol." 
          videoId="5d83fOhb7_M" 
        />
        <ContentSection 
          subtitle="PhoFi technique"
          title="3D+Ai" 
          description="Don't use what you're about to learn to portray your idol in a negative light or to encourage rivalries on the internet. PhoFiArt is a super power that connects you to your idol." 
          videoId="5d83fOhb7_M" 
        />
        <ContentSection 
          subtitle="PhoFi technique"
          title="Realistic" 
          description="Don't use what you're about to learn to portray your idol in a negative light or to encourage rivalries on the internet. PhoFiArt is a super power that connects you to your idol." 
          videoId="5d83fOhb7_M" 
        />
      </GridContainer>
    </ContentContainer>
  );
}