// Header.js
import * as React from 'react';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const AppBar = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(10px);
  box-shadow: none;
  z-index: 1000; 
  background:#f1f1f1;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  max-width: 1200px; 
  margin: 0 auto; 
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    height: 100%; 
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out; /* Transição simplificada */
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Header() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div style={{ position: 'relative', padding: '10px' }}> 
        <IconButton
          onClick={toggleDrawer(false)}
          sx={{ 
            position: 'absolute', 
            top: 8, 
            left: '30px;',
            transform: 'translateX(-50%)' 
          }} 
        >
          <CloseIcon />
        </IconButton>

        <List sx={{ padding: '10px', marginTop: '50px' }}> 
          <ListItem button sx={{ mb: 2 }}> {/* Adiciona margem inferior */}
            <ListItemText primary="About" primaryTypographyProps={{ fontFamily: '"Lato", sans-serif', fontWeight: 700, fontSize:'28px' }} /> 
          </ListItem>
          <ListItem button sx={{ mb: 2 }}>
            <ListItemText primary="Community" primaryTypographyProps={{ fontFamily: '"Lato", sans-serif', fontWeight: 700, fontSize:'28px' }} />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Contact" primaryTypographyProps={{ fontFamily: '"Lato", sans-serif', fontWeight: 700, fontSize:'28px' }} />
          </ListItem>
        </List>
      </div>
    </div>
  );

  return (
    <AppBar>
      <Toolbar>
        <MenuIcon onClick={toggleDrawer(true)} style={{ fontSize: 24, color: 'black' }} />
      </Toolbar>

      <StyledDrawer 
        anchor="top" 
        open={open} 
        onClose={toggleDrawer(false)} 
        TransitionComponent={Transition} 
        transitionDuration={400}
      >
        {list()}
      </StyledDrawer>
    </AppBar>
  );
}