// ComingSoon.js
import * as React from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';

const PageContainer = styled.div`
  height: 100vh;
  display: flex; 
  justify-content: center; 
  align-items: center;
 */
`;

const ComingSoonMessage = styled.h1`
  font-size: 3.5rem;
  margin: 20px;
  color: black;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-align: center;
  letter-spacing: -2px; 
  line-height: 0.9; 
`;

export default function ComingSoon() {
  useEffect(() => {
    window.location.href = 'https://hotmart.com/en/club/phofipilot?from=HomePage'; // Substitua pelo URL desejado
  }, []); // O array vazio garante que o efeito seja executado apenas uma vez, quando o componente é montado

  return null; // Não precisa renderizar nada, pois o redirecionamento acontecerá imediatamente
}

/* export default function ComingSoon() {
  return (
    <PageContainer>
      <ComingSoonMessage>Coming Soon</ComingSoonMessage>
    </PageContainer>
  );
} */